
export default {
    data() {
        return {
            brandMenu : null,
            alreadyOnRequest : false,
            open : false,
            scrollable:false,
            milliseconds: 10000,
            intervalHolder : null,
            mouseover: false,
            timeoutHolder : null
        }
    },
    computed:{
        top(){
            return this.$store.state.scroll.top
        }
    },
    beforeDestroy() {
        if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
        if(this.intervalHolder) clearInterval(this.intervalHolder);
    },
    watch: {
        open(t){
            if(t){
                this.handleOpen();
            }else{
                clearInterval(this.intervalHolder);
            }
        },
        top(t){
            if(t > 0){
                this.open = false;
            }
        }
    },
    created() {
        this.getBrandMenu();
    },
    mounted() {
        this.getBrandMenu();
    },
    methods: {
        handleOpen(){
            this.intervalHolder = setInterval(() => {
                if(!this.mouseover) this.open = false;
            },this.milliseconds)
        },
        getBrandMenu(){
            if(!this.brandMenu && !this.alreadyOnRequest){
                this.alreadyOnRequest = true;
                this.$axios.get("storyblok/stories/system/brandmenu",{params:{flushcache : 'yes'}}).then(axRes => {
                    this.alreadyOnRequest = false;
                    this.brandMenu = axRes.data.story.content;
                    this.$nextTick(()=> {
                        this.calcDim();
                    })
                }).catch(axErr => {
                    console.log(axErr.stack);
                })
            }
        },
        calcDim(){
            if(this.$el && this.$el.querySelector){
                const brandList =  this.$el.querySelector(".brandList"); 
                this.scrollable = brandList.scrollWidth > window.innerWidth;
                this.$nextTick(()=> {
                    this.timeoutHolder = setTimeout(() => {
                        this.calcDim();
                    }, 400);
            })}
        }

    },
}
